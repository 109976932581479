/* eslint-disable no-restricted-globals */
import React from 'react';
import { RouteComponentProps, Router } from 'react-router-dom';
import { Card, Button } from 'antd';
import { IconFont } from '../../component/icon-font';
import './home.scss';

const { Meta } = Card;
interface HomeProps extends RouteComponentProps {
  status: string;
  onLeaveOrJoinSession: () => void;
}
const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { history, status, onLeaveOrJoinSession } = props;
  const onCardClick = (type: string) => {
    // console.log(type ,${location.search})
    history.push(`/${type}${location.search}`);
  };

  const featureList = [
    {
      key: 'video',
      icon: 'icon-meeting',
      title: 'Chamada de vídeo e áudio',
      description: 'Layout de galeria, chamada de áudio e vídeo com opção de compartilhar tela.'
    },
    // {
    //   key: 'chat',
    //   icon: 'icon-chat',
    //   title: 'Session chat',
    //   description: 'Session Chat, Chat Priviledge'
    // },
    // {
    //   key: 'command',
    //   icon: 'icon-chat',
    //   title: 'Command Channel chat',
    //   description: 'Session Command Channel chat'
    // },
    // {
    //   key: 'Subsession',
    //   icon: 'icon-group',
    //   title: 'Subsession',
    //   description: 'Open/Close Subsession, Assign/Move Participants into Subsession, Join/Leave Subsession'
    // },
    {
      key: 'preview',
      icon: 'icon-meeting',
      title: 'Prévia de vídeo',
      description: 'Teste antes o seu áudio e vídeo.'
    }
  ];
  let actionText;
  if (status === 'connected') {
    actionText = 'Sair';
  } else if (status === 'closed') {
    actionText = 'Entrar';
  }
  return (
    <div>
      <div className="nav">
        <a href="/" className="navhome">
          <img src="./logo.svg" alt="Home" />
          <span>Playwav Video</span>
        </a>
        {/* <div className="navdoc">
          <a href="https://marketplace.zoom.us/docs/sdk/video/web/reference" target="_blank" rel="noreferrer">
            <span>API Reference</span>
          </a>

          <a href="https://marketplace.zoom.us/docs/sdk/video/web/build/sample-app" target="_blank" rel="noreferrer">
            <span>Doc</span>
          </a>
        </div> */}
        {actionText && (
          <Button type="link" className="navleave" onClick={onLeaveOrJoinSession}>
            {actionText}
          </Button>
        )}
      </div>

      <div className="home">
        <h1>Chamada de vídeo Zoom</h1>
        <div className="home-alert">
          <div className="card">
            <p>
              Antes de iniciar qualquer aula, lembre-se de <strong> ativar tanto o vídeo quanto o áudio</strong>
              <br />
              para garantir que sua experiência de aprendizado seja completa. Preparado para começar?
            </p>
            <br />
            <strong>Certifique-se de que ambos estejam ligados!</strong>
          </div>
        </div>
        <div className="feature-entry">
          {featureList.map((feature) => {
            const { key, icon, title, description } = feature;
            return (
              <Card
                cover={<IconFont style={{ fontSize: '72px' }} type={icon} />}
                hoverable
                style={{ width: 320 }}
                className="entry-item"
                key={key}
                onClick={() => onCardClick(key)}
              >
                <Meta title={title} description={description} />
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Home;
