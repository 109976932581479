const urlParams = new URLSearchParams(window.location.search);
const name = urlParams.get('name');
const signature = urlParams.get('signature');
const role = urlParams.get('role_type');

export const devConfig = {
  sdkKey: process.env.REACT_APP_ZOOM_VIDEO_SDK_KEY,
  sdkSecret: process.env.REACT_APP_ZOOM_VIDEO_SDK_SECRET,
  webEndpoint: 'zoom.us',
  topic: '',
  name: name,
  password: '',
  signature: signature,
  sessionKey: '',
  userIdentity: '',
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  role: role,
};